import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useUsersSession } from './database/auth';


function MyClaps(props) {
  const { user } = props;
  const users = useUsersSession();
  const { claps } = user;
  const today = moment().format('YYYY-MM-DD');

  return (
    <div>
      {
        !!claps
        && !!claps[today]
        && !!Object.values(claps[today])
        && (
        <div className="-mb-5 p-3 text-xs text-gray-600 text-right">
          <h3 className="font-bold mb-1">Received claps - today</h3>
          {
            Object.keys(claps[today]).map((userKey) => {
              const member = users.find(u => u.uid === userKey);
              return (
                <div>
                  {`${member.name}: ${claps[today][userKey]}`}
                </div>
              );
            })
          }
        </div>
        )
      }
    </div>
  );
}

MyClaps.propTypes = {
  user: PropTypes.object.isRequired,
};

export default MyClaps;
