import React from 'react';
import * as firebase from 'firebase/app';
import moment from 'moment';
import 'firebase/firestore';
import { db } from '.';


// Create
export const createUser = ({ user }) => {
  const userData = {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
  };

  return db.collection('users').doc(user.uid).set({
    name: userData.displayName,
    userData,
    lastChanged: moment().format('X'),
  }, { merge: true });
};


// Read
export function useUser(uid) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);

  React.useEffect(
    () => {
      const unsubscribe = firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .onSnapshot((doc) => {
          console.count();
          console.log('user');
          const data = doc.data();
          setLoading(false);
          setUser({
            uid: doc.id,
            ...data,
          });
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid],
  );

  return {
    error,
    loading,
    user,
  };
}


// Read - find user
export function getUserData({ users, uid }) {
  const user = users.find(userData => userData.uid === uid) || null;
  return user;
}


// Read - all users
export function useUsers(uid) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState(null);

  React.useEffect(
    () => {
      console.log('> start query all users');
      const unsubscribe = firebase
        .firestore()
        .collection('users')
        .orderBy('lastChanged', 'desc')
        .onSnapshot((snapshot) => {
          console.count();
          console.log('all users');
          const newUsers = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            newUsers.push(data);
          });

          setLoading(false);
          setUsers(newUsers);
        }, (err) => {
          setError(err);
        });
      return () => {
        console.log('> stop query all users');
        unsubscribe();
      };
    },
    [uid],
  );

  return {
    error,
    loading,
    users,
  };
}


// Update - change name
export const changeUserName = ({ user, newName }) => {
  if (!newName || user.name === newName) {
    return null;
  }

  const batch = db.batch();

  // get all sprints that you own, update that userData
  return db.collection('sprints').where('owner.uid', '==', user.uid).get()
    .then((snapshot) => {
      console.count();
      console.log('changeUserName user sprints');
      snapshot.forEach((doc) => {
        const sprintRef = db.collection('sprints').doc(doc.id);
        batch.update(sprintRef, {
          'owner.name': newName,
        });
      });
      return true;
    })
    .then(() => {
      const userRef = db.collection('users').doc(user.uid);
      batch.update(userRef, {
        name: newName,
        lastChanged: moment().format('X'),
      });
      return batch.commit();
    });

  // get all sprints you joined, update that userData
};


// Update - add claps
export const addClaps = ({ member, user, amount }) => {
  const today = moment().format('YYYY-MM-DD');

  db.collection('users').doc(member.uid).update({
    [`claps.${today}.${user.uid}`]: firebase.firestore.FieldValue.increment(amount),
  });
};


// Update - status
export const changeUserStatus = ({ user, newStatus }) => {
  if (user.status === newStatus) {
    return null;
  }

  return db.collection('users').doc(user.uid).update({
    status: newStatus,
    lastChanged: moment().format('X'),
  });
};


// Delete
