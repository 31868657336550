import React from 'react';
import { useSession, logout } from './database/auth';
import SprintForm from './SprintForm';
import SprintWrapper from './SprintWrapper';
import CurrentUsers from './CurrentUsers';
import StatusForm from './StatusForm';
import MyClaps from './MyClaps';

function MainPanel() {
  const user = useSession();
  const [currentSprintBlock, setCurrentSprintBlock] = React.useState(null);

  if (!user) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  if (!user.sprint && currentSprintBlock) {
    setCurrentSprintBlock(null);
  }

  document.title = 'timer.party';

  let background = 'bg-gray-300';
  if (currentSprintBlock && currentSprintBlock.kind === 'focus') {
    background = 'bg-blue-200';
  }
  if (currentSprintBlock && currentSprintBlock.kind === 'break') {
    background = 'bg-green-200';
  }

  return (
    <div className={`${background} px-5 lg:px-10 min-h-screen text-gray-700`}>
      <div className="max-w-md mx-auto leading-normal">

        <div className="bg-gray-100 shadow">

          <div className="p-3 flex items-end justify-between">
            <h1 className="text-indigo-600 font-bold text-base leading-tight">
              timer.party
              <span
                className="ml-1"
                role="img"
                aria-label="Party"
                aria-hidden
              >
                🥳
              </span>
            </h1>
            <div className="flex items-center">
              {
                !!('Notification' in window)
                && Notification.permission !== 'granted'
                && (
                  <button
                    type="button"
                    className="bg-indigo-500 hover:bg-indigo-600 text-white text-xs px-1 rounded leading-normal focus:outline-none"
                    onClick={(
                      () => {
                        Notification.requestPermission();
                      }
                    )}
                  >
                    <i className="fas fa-bell" />
                  </button>
                )
              }
              <button
                type="button"
                className="ml-2 bg-red-300 hover:bg-red-400 text-white text-xs px-1 rounded leading-normal focus:outline-none"
                onClick={() => { logout(); }}
              >
                logout
              </button>
            </div>
          </div>

          <div className="flex justify-center border-b pt-3">
            <h3 className="pb-1 px-3 font-bold rounded-bxl-lg border-b-2 border-indigo-500">Timer Party Alpha</h3>
          </div>

        </div>

        <StatusForm user={user} />

        <MyClaps user={user} />


        <div className="text-gray-700 bg-white shadow rounded-lg p-5 mt-8 mb-5">
          {
            !user.sprint
            && (
              <SprintForm user={user} />
            )
          }
          {
            !!user.sprint
            && (
              <div>
                <SprintWrapper
                  sprintKey={user.sprint}
                  currentSprintBlock={currentSprintBlock}
                  setCurrentSprintBlock={setCurrentSprintBlock}
                />
              </div>
            )
          }
        </div>

        <div className={currentSprintBlock && currentSprintBlock.kind === 'focus' ? 'hidden' : null}>
          <CurrentUsers />
        </div>

      </div>
      <div className="mt-32 clearfix" />
    </div>
  );
}

export default MainPanel;
