import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSession, useUsersSession } from './database/auth';
import { removeSprint, leaveSprint } from './database/sprints';
import { getUserData } from './database/users';


function Sprint(props) {
  const user = useSession();
  const users = useUsersSession();
  const {
    sprint,
    currentSprintBlock,
    setCurrentSprintBlock,
  } = props;
  const {
    timeBlocks,
    endSprint,
    joiners,
  } = sprint;

  const owner = getUserData({ users, uid: sprint.owner });

  const [now, setNow] = React.useState(moment().valueOf());

  // Timer tick
  function tick() {
    setNow(moment().valueOf());
  }

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const currentBlock = timeBlocks.find(timeBlock => (
    timeBlock.startBlock <= now && timeBlock.endBlock > now
  )) || null;

  if (currentSprintBlock !== currentBlock) {
    setCurrentSprintBlock(currentBlock);

    // Sprint switched to another block
    if (('Notification' in window) && currentBlock) {
      try {
        const notificationMessage = currentBlock.kind === 'focus'
          ? 'Time for focus!'
          : 'Time for a break!';

        new Notification(notificationMessage); // eslint-disable-line no-new
      } catch (err) {
        console.error(err);
      }
    }

    // Sprint just ended
    if (('Notification' in window) && !currentBlock) {
      if (('Notification' in window)) {
        try {
          new Notification('Sprint is done!'); // eslint-disable-line no-new
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  const minutesLeft = currentBlock ? moment.duration(currentBlock.endBlock - now, 'milliseconds').minutes() : null;
  const secondsLeft = currentBlock ? moment.duration(currentBlock.endBlock - now, 'milliseconds').seconds() : null;
  const currentBlockTimer = currentBlock ? `${minutesLeft < 10 ? 0 : ''}${minutesLeft}:${secondsLeft < 10 ? 0 : ''}${secondsLeft}` : null;

  document.title = currentBlock
    ? `${currentBlockTimer} / ${currentBlock.kind} / timer.party`
    : 'Sprint done! / timer.party';


  return (
    <>
      <div className="-mt-3">
        {
          timeBlocks.map((timeBlock, index) => {
            const {
              minutes,
              kind,
              startBlock,
              endBlock,
            } = timeBlock;
            const title = kind === 'focus'
              ? `${minutes} minutes of focus`
              : `${minutes} minute break`;

            const key = `${minutes}_${index}`;

            // past timeBlock
            if (now >= endBlock) {
              return (
                <div
                  key={key}
                  className={
                    kind === 'focus'
                      ? 'mt-3 bg-blue-100 p-2 text-gray-600 rounded line-through'
                      : 'mt-3 bg-green-100 p-2 text-gray-600 rounded line-through'
                  }
                >
                  <i className="fas fa-check mr-2" />
                  { title }
                </div>
              );
            }

            // current timeBlock
            if (
              now >= startBlock
              && now < endBlock
            ) {
              return (
                <div
                  key={key}
                  className={
                    kind === 'focus'
                      ? 'mt-3 bg-blue-500 p-2 rounded text-white'
                      : 'mt-3 bg-green-500 p-2 rounded text-white'
                  }
                >
                  <div className="flex justify-between">
                    <div>
                      <i className="fas fa-arrow-right mr-2" />
                      { title }
                    </div>
                    <div>
                      { currentBlockTimer }
                    </div>
                  </div>
                  <div className="shadow w-full bg-gray-400 mt-2">
                    <div
                      className="bg-white text-xs leading-none h-1 text-center text-white"
                      style={{ width: `${(now - startBlock) / (endBlock - startBlock) * 100}%` }}
                    />
                  </div>
                </div>
              );
            }

            // upcoming timeBlock
            return (
              <div
                key={key}
                className={
                  kind === 'focus'
                    ? 'mt-3 bg-blue-100 text-gray-600 p-2 rounded'
                    : 'mt-3 bg-green-100 text-gray-600 p-2 rounded'
                }
              >
                { title }
              </div>
            );
          })
        }
      </div>


      { /*
        !!joiners
        && !!joiners.length
        && (
          <div className="mt-5 text-sm font-bold italic">
            {
              joiners.length === 1
              && `${joiners.length} person joined this party!`
            }
            {
              joiners.length > 1
              && `${joiners.length} people joined this party!`
            }
          </div>
        )
      */ }

      {
        user.uid === owner.uid
        && now >= endSprint
        && (
          <div className="mt-5 text-right">
            Party is over!
            <button
              type="button"
              className="ml-2 bg-gray-500 hover:bg-gray-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none"
              onClick={() => {
                removeSprint({ user, sprint });
              }}
            >
              close party
            </button>
          </div>
        )
      }

      {
        user.uid !== owner.uid
        && now >= endSprint
        && (
          <div className="mt-5 text-right">
            Sprint is done!
            <button
              type="button"
              className="ml-2 bg-gray-500 hover:bg-gray-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none"
              onClick={() => {
                leaveSprint({ user, sprint });
              }}
            >
              close party
            </button>
          </div>
        )
      }

      {
        user.uid === owner.uid
        && now < endSprint
        && (
          <div className="mt-5 text-right">
            <button
              type="button"
              className="ml-2 bg-gray-500 hover:bg-gray-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none"
              onClick={() => {
                removeSprint({ user, sprint });
              }}
            >
              cancel party
            </button>
          </div>
        )
      }
      {
        user.uid !== owner.uid
        && now < endSprint
        && (
          <div className="mt-10 text-right">
            <button
              type="button"
              className="ml-2 bg-gray-500 hover:bg-gray-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none"
              onClick={() => {
                leaveSprint({ user, sprint });
              }}
            >
              leave party
            </button>
          </div>
        )
      }
    </>
  );
}

Sprint.defaultProps = {
  currentSprintBlock: null,
};

Sprint.propTypes = {
  sprint: PropTypes.object.isRequired,
  currentSprintBlock: PropTypes.object,
  setCurrentSprintBlock: PropTypes.func.isRequired,
};

export default Sprint;
