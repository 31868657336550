import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import { db } from '.';


// Create
export const createSprint = ({
  startSprint,
  endSprint,
  totalMinutes,
  timeBlocks,
  user,
}) => {
  const sprintsRef = db.collection('sprints').doc(`${user.uid}_${startSprint}`);
  const userRef = db.collection('users').doc(user.uid);

  return sprintsRef.set({
    startSprint,
    endSprint,
    totalMinutes,
    timeBlocks,
    owner: user.uid,
  })
    .then(() => (
      userRef.update({
        sprint: `${user.uid}_${startSprint}`,
        lastChanged: moment().format('X'),
      })
    ));
};


// Read
export function useSprint(id) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [sprint, setSprint] = React.useState(null);

  React.useEffect(
    () => {
      console.log('start listening to sprint', id);
      const unsubscribe = firebase
        .firestore()
        .collection('sprints')
        .doc(id)
        .onSnapshot((doc) => {
          console.count();
          console.log('sprint');
          const data = doc.data();
          setLoading(false);
          setSprint({
            id: doc.id,
            ...data,
          });
        }, (err) => {
          console.log(err);
          setError(err);
        });
      return () => unsubscribe();
    },
    [id],
  );

  return {
    error,
    loading,
    sprint,
  };
}


// Read - all sprints in current time
export function useCurrentSprints() {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [currentSprints, setCurrentSprints] = React.useState([]);

  React.useEffect(() => {
    console.log('> start query current sprints');
    const unsubscribe = firebase
      .firestore()
      .collection('sprints')
      .where('endSprint', '>', moment().valueOf())
      .onSnapshot(
        (snapshot) => {
          console.count();
          console.log('current sprints');
          const sprints = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            sprints.push({
              id: doc.id,
              ...data,
            });
          });

          setLoading(false);
          setCurrentSprints(sprints);
        }, (err) => {
          setError(err);
        },
      );

    return () => {
      console.log('> stop query current sprints');
      unsubscribe();
    };
  }, []);

  return {
    error,
    loading,
    currentSprints,
  };
}


// Update - join
export const joinSprint = ({ user, sprint }) => {
  const batch = db.batch();


  const sprintsRef = db.collection('sprints').doc(sprint.id);
  const userRef = db.collection('users').doc(user.uid);

  batch.update(sprintsRef, {
    joiners: firebase.firestore.FieldValue.arrayUnion(user.uid),
  });

  batch.update(userRef, {
    sprint: sprint.id,
    lastChanged: moment().format('X'),
  });

  return batch.commit();
};


// Update - leave
export const leaveSprint = ({ user, sprint }) => {
  const batch = db.batch();

  const sprintsRef = db.collection('sprints').doc(sprint.id);
  const userRef = db.collection('users').doc(user.uid);

  batch.update(sprintsRef, {
    joiners: firebase.firestore.FieldValue.arrayRemove(user.uid),
  });

  batch.update(userRef, {
    sprint: firebase.firestore.FieldValue.delete(),
    lastChanged: moment().format('X'),
  });

  return batch.commit();
};


// Delete
export const removeSprint = ({ user, sprint }) => {
  const batch = db.batch();

  const sprintsRef = db.collection('sprints').doc(sprint.id);
  const userRef = db.collection('users').doc(user.uid);

  batch.update(sprintsRef, {
    canceled: true,
  });

  batch.update(userRef, {
    sprint: firebase.firestore.FieldValue.delete(),
    lastChanged: moment().format('X'),
  });

  console.log('cancel sprint');

  return batch.commit();
};
