import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { createSprint } from './database/sprints';

class SprintForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeBlocks: [
        {
          minutes: 25,
          kind: 'focus',
        },
        {
          minutes: 5,
          kind: 'break',
        },
      ],
    };
    this.setKind = this.setKind.bind(this);
    this.lessTime = this.lessTime.bind(this);
    this.moreTime = this.moreTime.bind(this);
    this.addTimeBlock = this.addTimeBlock.bind(this);
    this.removeTimeBlock = this.removeTimeBlock.bind(this);
    this.startSprint = this.startSprint.bind(this);
  }

  setKind({ index, kind }) {
    const { timeBlocks } = this.state;
    const newTimeBlocks = [...timeBlocks];
    newTimeBlocks[index].kind = kind;
    this.setState({ timeBlocks: newTimeBlocks });
  }

  lessTime({ index }) {
    const { timeBlocks } = this.state;
    const newTimeBlocks = [...timeBlocks];
    if (newTimeBlocks[index].minutes <= 5) {
      return;
    }
    newTimeBlocks[index].minutes -= 5;
    this.setState({ timeBlocks: newTimeBlocks });
  }

  moreTime({ index }) {
    const { timeBlocks } = this.state;
    const newTimeBlocks = [...timeBlocks];
    if (newTimeBlocks[index].minutes >= 60) {
      return;
    }
    newTimeBlocks[index].minutes += 5;
    this.setState({ timeBlocks: newTimeBlocks });
  }

  addTimeBlock() {
    const { timeBlocks } = this.state;
    const newTimeBlocks = [...timeBlocks];

    if (newTimeBlocks[newTimeBlocks.length - 1].kind === 'focus') {
      newTimeBlocks.push({
        minutes: 5,
        kind: 'break',
      });
    } else {
      newTimeBlocks.push({
        minutes: 25,
        kind: 'focus',
      });
    }
    this.setState({ timeBlocks: newTimeBlocks });
  }

  removeTimeBlock({ index }) {
    const { timeBlocks } = this.state;
    const newTimeBlocks = [...timeBlocks];
    if (newTimeBlocks.length <= 1) {
      return;
    }
    newTimeBlocks.splice(index, 1);
    this.setState({ timeBlocks: newTimeBlocks });
  }

  startSprint() {
    const { timeBlocks } = this.state;

    const totalMinutes = timeBlocks.reduce((a, b) => {
      const result = a + b.minutes;
      return result;
    }, 0);

    const start = moment();
    const end = start.clone().add(totalMinutes, 'minutes');

    const { user } = this.props;

    // calculate start / end per timeBlock
    let nextStartBlock = start.clone();
    const calcTimeBlocks = timeBlocks.map((timeBlock) => {
      const startBlock = nextStartBlock.valueOf();
      nextStartBlock = nextStartBlock.add(timeBlock.minutes, 'minutes');
      const endBlock = nextStartBlock.valueOf();
      return {
        ...timeBlock,
        startBlock,
        endBlock,
      };
    });

    createSprint({
      startSprint: start.valueOf(),
      endSprint: end.valueOf(),
      totalMinutes,
      timeBlocks: calcTimeBlocks,
      user,
    });
  }

  render() {
    const { timeBlocks } = this.state;
    return (
      <div>
        {
          timeBlocks.map((timeBlock, index) => {
            const { minutes, kind } = timeBlock;
            const key = `${minutes}_${index}`;
            return (
              <div
                key={key}
                className={
                  kind === 'focus'
                    ? 'flex justify-between mt-3 bg-blue-100 p-2 rounded'
                    : 'flex justify-between mt-3 bg-green-100 p-2 rounded'
                }
              >
                <div>
                  <button
                    type="button"
                    className="hover:bg-gray-300 text-normal p-1 px-2 rounded leading-tight focus:outline-none"
                    onClick={() => {
                      this.lessTime({ index });
                    }}
                  >
                    <i className="fas fa-chevron-left" />
                  </button>
                  <span className="px-2 inline-block text-center text-normal">
                    { minutes }
                    {':00'}
                  </span>
                  <button
                    type="button"
                    className="hover:bg-gray-300 text-normal p-1 px-2 rounded leading-tight focus:outline-none"
                    onClick={() => {
                      this.moreTime({ index });
                    }}
                  >
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className={
                      kind === 'focus'
                        ? 'bg-blue-500 text-white text-normal p-1 px-2 rounded leading-tight focus:outline-none cursor-default'
                        : 'hover:bg-blue-200 text-normal p-1 px-2 rounded leading-tight focus:outline-none'
                    }
                    onClick={() => {
                      this.setKind({ index, kind: 'focus' });
                    }}
                  >
                    focus
                  </button>
                  <button
                    type="button"
                    className={
                      kind === 'break'
                        ? 'ml-2 bg-green-500 text-white text-normal p-1 px-2 rounded leading-tight focus:outline-none cursor-default'
                        : 'ml-2 hover:bg-green-200 text-normal p-1 px-2 rounded leading-tight focus:outline-none'
                    }
                    onClick={() => {
                      this.setKind({ index, kind: 'break' });
                    }}
                  >
                    break
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className={
                      timeBlocks.length > 1
                        ? 'hover:bg-gray-300 text-normal p-1 px-2 rounded leading-tight focus:outline-none'
                        : 'opacity-50 text-normal p-1 px-2 rounded leading-tight focus:outline-none'
                    }
                    onClick={() => {
                      if (timeBlocks.length > 1) {
                        this.removeTimeBlock({ index });
                      }
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </button>
                </div>
              </div>
            );
          })
        }
        <div className="text-right mt-3 p-2 rounded">
          <button
            type="button"
            className="hover:bg-gray-300 text-normal p-1 px-2 rounded leading-tight focus:outline-none"
            onClick={() => {
              this.addTimeBlock();
            }}
          >
            <i className="fas fa-plus" />
          </button>
        </div>
        <div className="mt-10 text-center">
          <button
            type="button"
            className="ml-2 bg-indigo-500 hover:bg-indigo-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none"
            onClick={() => { this.startSprint(); }}
          >
            {'start a '}
            {
              timeBlocks.reduce((a, b) => {
                const result = a + b.minutes;
                return result;
              }, 0)
            }
            {' minute timer party '}
            <i className="fas fa-arrow-right ml-2" />
          </button>
        </div>
      </div>
    );
  }
}

SprintForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default SprintForm;
