import React from 'react';
import useAuth from './database/useAuth';
import { login } from './database/auth';
import userContext from './database/userContext';
import MainPanel from './MainPanel';


function App() {
  const { initializing, user, users } = useAuth();

  if (initializing) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="max-w-sm mx-auto mt-32 leading-normal text-center">
        <h1 className="text-indigo-500 font-bold text-lg mb-5">timer.party</h1>
        <h2 className="text-gray-700 font-bold text-lg">
          Work with focus, create synergy.
        </h2>
        <button
          type="button"
          className="mt-10 bg-indigo-500 hover:bg-indigo-600 text-white text-normal py-2 px-4 rounded focus:outline-none"
          onClick={() => { login(); }}
        >
          {'sign in / log in'}
          <i className="ml-2 fas fa-arrow-right" />
        </button>
      </div>
    );
  }

  return (
    <userContext.Provider value={{ user, users }}>
      <MainPanel />
    </userContext.Provider>
  );
}

export default App;
