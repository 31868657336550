import React from 'react';
import PropTypes from 'prop-types';
import { useSprint } from './database/sprints';
import Sprint from './Sprint';


function SprintWrapper(props) {
  const { sprintKey, currentSprintBlock, setCurrentSprintBlock } = props;
  const { sprint } = useSprint(sprintKey);

  if (!sprint || !sprint.startSprint || !sprint.timeBlocks) {
    return (
      <div className="text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  return (
    <Sprint
      sprint={sprint}
      currentSprintBlock={currentSprintBlock}
      setCurrentSprintBlock={setCurrentSprintBlock}
    />
  );
}

SprintWrapper.defaultProps = {
  currentSprintBlock: null,
};

SprintWrapper.propTypes = {
  sprintKey: PropTypes.string.isRequired,
  currentSprintBlock: PropTypes.object,
  setCurrentSprintBlock: PropTypes.func.isRequired,
};

export default SprintWrapper;
