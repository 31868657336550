import * as firebase from 'firebase/app';
import 'firebase/firestore';

export const config = {
  apiKey: 'AIzaSyD6vfambtGf2WtzBvVm_dD3om1pI0shxew',
  authDomain: 'wepomodoro.firebaseapp.com',
  databaseURL: 'https://wepomodoro.firebaseio.com',
  projectId: 'wepomodoro',
  storageBucket: 'wepomodoro.appspot.com',
  messagingSenderId: '474043523495',
};

firebase.initializeApp(config);

export const db = firebase.firestore();
