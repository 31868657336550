import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { createUser, getUserData } from './users';
import { db } from '.';


const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const authUser = firebase.auth().currentUser;
    return {
      initializing: !authUser,
      user: authUser ? authUser.uid : null,
    };
  });

  const unsubscribeUser = React.useRef();

  function onChange(authUser) {
    if (!authUser) {
      setState({
        initializing: false,
        user: null,
      });
      return;
    }

    if (unsubscribeUser.current) {
      unsubscribeUser.current();
    }

    // Try to load user.
    db.collection('users').doc(authUser.uid).get()
      .then((doc) => {
        console.count();
        console.log('user');
        // Does the user exists?
        if (doc.exists) {
          const userData = {
            uid: authUser.uid,
            displayName: authUser.displayName,
            email: authUser.email,
          };

          if (
            doc.data().userData
            && userData
            && doc.data().userData.displayName === userData.displayName
            && doc.data().userData.email === userData.email
          ) {
            return true;
          }
        }
        return createUser({ user: authUser });
      })
      .then(() => {
        // start listening to all users.
        unsubscribeUser.current = firebase
          .firestore()
          .collection('users')
          .orderBy('lastChanged', 'desc')
          .onSnapshot((snapshot) => {
            console.count();
            console.log('users');
            const users = [];
            snapshot.forEach((doc) => {
              const data = doc.data();
              users.push({
                uid: doc.id,
                ...data,
              });
            });
            setState({
              initializing: false,
              user: getUserData({ users, uid: authUser ? authUser.uid : null }),
              users,
            });
          }, (err) => {
            console.log(err);
            setState({
              initializing: false,
              user: null,
              users: null,
              error: err,
            });
          });
      });
  }

  React.useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged(onChange);
    return () => {
      if (unsubscribeUser.current) {
        unsubscribeUser.current();
      }
      unsubscribeAuth();
    };
  }, []);

  return state;
};

export default useAuth;
