import { useContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import userContext from './userContext';

const provider = new firebase.auth.GoogleAuthProvider();

export const useSession = () => {
  const { user } = useContext(userContext);
  return user;
};

export const useUsersSession = () => {
  const { users } = useContext(userContext);
  return users;
};

export const login = () => {
  firebase.auth().signInWithPopup(provider);
};

export const logout = () => {
  firebase.auth().signOut();
};
