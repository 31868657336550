import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import TextareaAutosize from 'react-textarea-autosize';
import { changeUserStatus, changeUserName } from './database/users';


function StatusForm(props) {
  const { user } = props;
  const { name, status } = user;
  const [editing, setEditing] = React.useState();
  const [newStatus, setNewStatus] = React.useState(status);
  const [newName, setNewName] = React.useState(name);

  if (editing) {
    return (
      <div
        className="bg-white shadow-lg p-5 rounded-b-lg"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changeUserName({ user, newName });
            changeUserStatus({ user, newStatus });
            setEditing(false);
          }}
        >
          <div className="mb-3">
            <input
              className="block w-full p-3 bg-gray-200 rounded-lg focus:outline-none text-base font-bold"
              value={newName}
              onChange={(event) => {
                event.preventDefault();
                setNewName(event.target.value);
              }}
              placeholder="What is your name?"
              type="text"
            />
          </div>
          <div>
            <TextareaAutosize
              useCacheForDOMMeasurements
              className="block w-full p-3 bg-gray-200 rounded-lg focus:outline-none text-sm font-mono"
              value={newStatus}
              onChange={(event) => {
                setNewStatus(event.target.value);
              }}
              placeholder="What is your status?"
              rows={3}
            />
            { /*
            <input
              className="block w-full p-3 bg-gray-200 rounded-lg focus:outline-none text-base"
              value={newStatus}
              onChange={(event) => {
                setNewStatus(event.target.value);
              }}
              placeholder="What is your status?"
              type="text"
            />
            */ }
          </div>
          <div className="mt-5 text-right">
            <button
              type="button"
              className="ml-2 bg-gray-500 hover:bg-gray-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none"
              onClick={() => {
                setEditing(false);
              }}
            >
              cancel
            </button>
            <button
              type="submit"
              className={
                user.status !== newStatus
                || user.name !== newName
                  ? 'ml-2 bg-indigo-500 hover:bg-indigo-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none'
                  : 'ml-2 bg-indigo-500 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none opacity-50 cursor-default'
              }
            >
              save
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <button
      type="button"
      className="block w-full text-left shadow p-3 bg-white relative rounded-b-lg border-2 border-transparent hover:shadow-lg focus:outline-none leading-normal"
      onClick={() => {
        setEditing(true);
      }}
    >
      <h3 className="text-base font-bold">{ name }</h3>

      {
        !!status
        && (
          <div className="bg-white italic">
            <div className="text-gray-600 markdown">
              <Markdown>{`${status}`}</Markdown>
            </div>
          </div>
        )
      }
    </button>
  );
}

StatusForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default StatusForm;
