import React from 'react';
import Markdown from 'markdown-to-jsx';
import { useSession, useUsersSession } from './database/auth';
import { useCurrentSprints } from './database/sprints';
import CurrentUsersSprint from './CurrentUsersSprint';
import Clap from './Clap';


function CurrentUsers() {
  const { currentSprints } = useCurrentSprints();
  const users = useUsersSession();
  const user = useSession();

  if (!users || !users.length) {
    return null;
  }

  const sprints = currentSprints.filter(currentSprint => ((!currentSprint.canceled)));

  // users in current sprint
  const usersInYourSprint = user.sprint
    ? users.filter(member => (member.sprint === user.sprint && user.uid !== member.uid))
    : null;

  const restOfTheTeam = users.filter(member => (
    (
      member.sprint !== user.sprint
      || !user.sprint
    )
    && user.uid !== member.uid
  ));

  return (
    <div>
      {
        !!usersInYourSprint
        && !!usersInYourSprint.length
        && (
          <>
            <h3 className="mt-5 mb-1 font-bold">
              Party members
              <span
                className="ml-1"
                role="img"
                aria-label="Party"
                aria-hidden
              >
                🎉
              </span>
            </h3>
            <div className="bg-white shadow rounded-lg p-5 mb-5">
              {
                usersInYourSprint.map((member, index) => {
                  const {
                    name,
                    status,
                    userData,
                  } = member;

                  if (userData && userData.uid === user.uid) {
                    return null;
                  }

                  return (
                    <div className={
                      index === usersInYourSprint.length - 1
                        ? 'border-gray-200'
                        : 'pb-5 mb-5 border-b border-gray-200'
                    }
                    >
                      <div className="flex justify-between">
                        <div>
                          <h3 className="text-base font-bold">{ name }</h3>
                          {
                            !!status
                            && (
                              <div className="italic rounded flex justify-between text-gray-600 markdown">
                                <Markdown>{`${status}`}</Markdown>
                              </div>
                            )
                          }
                          <div className="hidden mt-2 text-xs text-gray-400 italic">
                            minutes of focus - today: 12 / this week: 44 / all time: 650
                          </div>
                        </div>
                        <Clap member={member} user={user} />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </>
        )
      }

      {
        !!restOfTheTeam
        && !!restOfTheTeam.length
        && (
          <>
            <h3 className="mt-5 mb-1 font-bold">Team members</h3>
            <div className="bg-white shadow rounded-lg p-5">
              {
                restOfTheTeam.map((member, index) => {
                  const {
                    name,
                    status,
                    sprint,
                    userData,
                  } = member;

                  if (userData && userData.uid === user.uid) {
                    return null;
                  }

                  return (
                    <div className={
                      index === restOfTheTeam.length - 1
                        ? 'border-gray-200'
                        : 'pb-5 mb-5 border-b border-gray-200'

                    }
                    >
                      <div className="flex justify-between">
                        <div>
                          <h3 className="text-base font-bold">{ name }</h3>
                          {
                            !!status
                            && (
                              <div className="italic rounded flex justify-between text-gray-600 markdown">
                                <Markdown>{`${status}`}</Markdown>
                              </div>
                            )
                          }
                          <div className="hidden mt-2 text-xs text-gray-400 italic">
                            minutes of focus - today: 12 / this week: 44 / all time: 650
                          </div>
                        </div>
                        <Clap member={member} user={user} />
                      </div>

                      {
                        !!sprint
                          && !!sprints
                          && !!sprints.length
                          && !!sprints.find(currentSprint => currentSprint.id === sprint)
                          && (
                            <div className="mt-3 p-3 bg-gray-100 rounded-lg">
                              <CurrentUsersSprint
                                user={user}
                                member={member}
                                sprint={sprints.find(currentSprint => currentSprint.id === sprint)}
                              />
                            </div>
                          )
                        }

                    </div>
                  );
                })
              }
            </div>
          </>
        )
      }
    </div>
  );
}

export default CurrentUsers;
