import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { useUsersSession } from './database/auth';
// import { getUserData } from './database/users';
import { joinSprint } from './database/sprints';


function CurrentUsersSprint(props) {
  // const users = useUsersSession();
  const { sprint, user } = props;
  const {
    timeBlocks,
    totalMinutes,
  } = sprint;
  // const owner = getUserData({ users, uid: sprint.owner });
  const [now, setNow] = React.useState(moment().valueOf());

  // Timer tick
  function tick() {
    setNow(moment().valueOf());
  }

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const currentBlock = timeBlocks.find(timeBlock => (
    timeBlock.startBlock <= now && timeBlock.endBlock > now
  )) || null;

  const minutesLeft = currentBlock ? moment.duration(currentBlock.endBlock - now, 'milliseconds').minutes() : null;
  const secondsLeft = currentBlock ? moment.duration(currentBlock.endBlock - now, 'milliseconds').seconds() : null;
  const currentBlockTimer = currentBlock ? `${minutesLeft < 10 ? 0 : ''}${minutesLeft}:${secondsLeft < 10 ? 0 : ''}${secondsLeft}` : null;


  return (
    <div>
      <div className="text-xs text-gray-500">
        { `Is in a ${totalMinutes} minute timer party` }
      </div>

      <div className="mt-1">
        {
          timeBlocks.map((timeBlock, index) => {
            const {
              minutes,
              kind,
              startBlock,
              endBlock,
            } = timeBlock;
            const title = kind === 'focus'
              ? `${minutes} minutes of focus`
              : `${minutes} minute break`;

            const key = `${minutes}_${index}`;

            // current timeBlock
            if (
              now >= startBlock
              && now < endBlock
            ) {
              return (
                <div
                  key={key}
                  className={
                    kind === 'focus'
                      ? 'bg-blue-500 p-2 rounded text-white'
                      : 'bg-green-500 p-2 rounded text-white'
                  }
                >
                  <div className="flex justify-between">
                    <div>
                      <i className="fas fa-arrow-right mr-2" />
                      { title }
                    </div>
                    <div>
                      { currentBlockTimer }
                    </div>
                  </div>
                  <div className="shadow w-full bg-gray-400 mt-2">
                    <div
                      className="bg-white text-xs leading-none h-1 text-center text-white"
                      style={{ width: `${(now - startBlock) / (endBlock - startBlock) * 100}%` }}
                    />
                  </div>
                </div>
              );
            }

            return null;
          })
        }
      </div>

      <div className="flex mt-1">
        {
          timeBlocks.map((timeBlock, index) => {
            const {
              minutes,
              kind,
              startBlock,
              endBlock,
            } = timeBlock;
            const key = `${minutes}_${index}`;

            const percentage = (minutes / totalMinutes) * 100;

            if (now >= endBlock) {
              return (
                <div
                  key={key}
                  className={
                    kind === 'focus'
                      ? 'h-1 bg-blue-200'
                      : 'h-1 bg-green-200'
                  }
                  style={{ width: `${percentage}%` }}
                />
              );
            }

            // current timeBlock
            if (
              now >= startBlock
              && now < endBlock
            ) {
              return (
                <div
                  key={key}
                  className={
                    kind === 'focus'
                      ? 'h-1 bg-blue-500'
                      : 'h-1 bg-green-500'
                  }
                  style={{ width: `${percentage}%` }}
                />
              );
            }

            // upcoming timeBlocks
            return (
              <div
                key={key}
                className={
                  kind === 'focus'
                    ? 'h-1 bg-blue-200'
                    : 'h-1 bg-green-200'
                }
                style={{ width: `${percentage}%` }}
              />
            );
          })
        }
      </div>

      {
        !user.sprint
        && (
          <div className="mt-5 text-right">
            <button
              type="button"
              className="bg-indigo-500 hover:bg-indigo-600 text-white text-normal p-1 px-4 rounded leading-normal focus:outline-none"
              onClick={() => {
                joinSprint({ user, sprint });
              }}
            >
              {'join party'}
              <i className="fas fa-arrow-right ml-2" />
            </button>
          </div>
        )
      }
    </div>
  );
}

CurrentUsersSprint.propTypes = {
  sprint: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default CurrentUsersSprint;
