import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { addClaps } from './database/users';


function Clap(props) {
  const { member, user } = props;
  const [newClaps, setNewClaps] = React.useState(0);

  const today = moment().format('YYYY-MM-DD');

  React.useEffect(
    () => {
      const timer = setTimeout(() => {
        if (!newClaps) {
          return;
        }
        addClaps({ member, user, amount: newClaps });
        setNewClaps(0);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    },
    [newClaps, member, user],
  );

  const { claps } = member;

  return (
    <div className="flex-no-shrink ml-3 text-center text-gray-500">
      <button
        type="button"
        className="bg-grey-light hover:bg-white text-xl border rounded-full w-10 h-10 flex items-center justify-center focus:outline-none hover:border-green-500 hover:text-green-500"
        onClick={() => {
          setNewClaps(newClaps + 1);
        }}
      >
        <i className="far fa-hand-paper" />
      </button>
      <div className="text-xs">
        {
          !!newClaps
          && (
            <div className="text-green-500 font-bold">
              { `+${newClaps}` }
            </div>
          )
        }
        {
          !newClaps
          && (
            <div>
              {
                !!claps
                && !!claps[today]
                && !!Object.values(claps[today])
                && !!Object.values(claps[today]).reduce((a, b) => (a + b), 0)
                && Object.values(claps[today]).reduce((a, b) => (a + b), 0)
              }
            </div>
          )
        }
      </div>
    </div>
  );
}


Clap.propTypes = {
  user: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
};

export default Clap;
